<template>
    <section>
        <div v-if="loader">En cours de chargement</div>
        <span v-else>
            <div  class="container-fluid d-flex justify-content-between">
                <div class="col-lg-3 pl-0">
                    <p class="mt-5 mb-2">
                    <b>Administrateur</b>
                    </p>
                    <p>Mon échappée Belle,
                    <br>64 avenue Saint Exupéry,
                    <br>81600 GAILLAC.</p>

                    <br>
                    <b>Date et heure: </b>
                    <p>{{dateToFr2(reservationStartDate)}}</p>
                </div>
                <div class="col-lg-3 pr-0">
                    <p class="mt-5 mb-2 text-right">
                    <b>Pour</b>
                    </p>
                    <p class="text-right" v-if="reservation.on_behalf_of.name">{{reservation.on_behalf_of.name}}</p>
                    <p class="text-right" v-if="reservation.on_behalf_of.phone">{{reservation.on_behalf_of.phone}}</p>
                    <p class="text-right" v-if="reservation.on_behalf_of.email">{{reservation.on_behalf_of.email}}</p>
                </div>
            </div>
            <!-- <button @click="openEditService()">Modifier la réservation</button> -->
            <add-service class="computer"
                ref="add_service"
                :modalId="'addservice1'"
                text="Modifier"
                key="addservice1"
                @updateService="addService"
            />
            <!-- tableau réservation -->
            <div class="container-fluid mt-5 d-flex justify-content-center w-100"> 
                <div class="table-responsive w-100">
                    <table class="table" >
                    <thead>
                        <tr class="bg-dark text-white">
                        <th>#</th>
                        <th>Prestation</th>
                        <th class="text-left">Durée</th>
                        <th class="text-right">Prix</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-right" v-for="(appointment, appointmentId) in reservation.appointments" :key="appointmentId">
                            <td class="text-left">{{appointmentId+1}}</td>
                            <td class="text-left">{{appointment.service.name}}</td>
                            <td class="text-left">{{calculTimes(appointment.service.duration)}}</td>
                            <td class="text-right"> {{appointment.service.price}} € </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <button class="btn btn-outline-danger" @click="deleteReservation()">Supprimer</button>
                <button class="btn btn-outline-primary" @click="cancelReservation()">Annuler</button>
            </div>
        </span> <!-- chargement -->
    </section>
</template>
<script>
import axios from 'axios'
import { dateMixin } from '../mixins/date'
import addService from '../../../components/functions/add-service.vue'
export default {
    name:"reservationDetails",
    components:{
        addService
    },
    mixins:[dateMixin],
    data(){
        return{
            ulid:'',
            reservation:{},
            loader:true
        }
    },
    computed:{
        reservationStartDate(){
            // slice() est utilisé pour créer une copie du tableau réservation sinon ça changera le this.reservation d'origine 
            let sort_date = this.reservation.appointments.slice().sort((appointmentA, appointmentB) => new Date(appointmentA.start_hour) - new Date(appointmentB.start_hour))
            let start_date = sort_date[0]
            console.log('date début', start_date.start_hour);
            return start_date.start_hour
        }
    },
    methods:{
        getReservation(){
            axios.get(`reservations/${this.$route.params.ulid}`)
            .then(resGetReservation => {
                this.reservation = resGetReservation.data.result
                // récupération de la date et heure de début réservation
                // this.reservationStartDate()
                this.loader =false
            })
            .catch(errGetReservation=> {
                console.log({errGetReservation});
            })
        },
        cancelReservation(){
            axios.put(`reservations/${this.$route.params.ulid}`,{
                status:'cancelled'
            })
            .then(resCancelReservation=>{
                resCancelReservation
                this.$router.go(-1)
            })
            .catch(errCancelReservation => {
                errCancelReservation
                alert('une erreur est survenue')
            })
        },
        deleteReservation(){
            axios.delete(`reservations/${this.$route.params.ulid}`)
            .then(resDeleteReservation => {
                resDeleteReservation
                this.$router.go(-1)
                this.loader =false
            })
            .catch(errDeleteReservation=> {
                console.log({errDeleteReservation});
            })
        },
        openEditService(){
            this.$refs.add_service.openModalWithParameter(this.reservationStartDate, this.reservation.ulid);
        },
        addService(){
            console.log("hello");
        }
    },
    mounted(){
        this.getReservation()
    }
}
</script>